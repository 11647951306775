<template>
  <div>
    <slot
      name="enabled"
      :onEnabledChange="onEnabledChange"
      :value="enabled"
    ></slot>

    <v-text-field
      outlined
      dense
      maxlength="50"
      counter
      @keyup.enter="triggerFilter"
      v-model="filterValue"
    >
    </v-text-field>
  </div>
</template>

<script lang="babel" type="text/babel">
import filter from '@/components/list/listSearchAndFilter/filter.js'
export default {
  mixins: [filter],
}
</script>

<style lang="sass" type="text/sass" scoped></style>